import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import FormLabel from "@material-ui/core/FormLabel";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Box from "@material-ui/core/Box";
import { Chip } from "@material-ui/core";
import { DatabaseDateRangePicker } from "../../views/DatabaseLogs/DatabaseLogsTimeRange";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import "./customCalender.scss";
import LevelComponent from "../../shared/levelComponent"
import DbusersComponent from "../../shared/dbusers.js";
import QueryStatementComponent from "../../shared/queryStatement.js"
import { SET_USERS, SET_QUERY_STATEMENT, SET_LOCATION } from "../../../redux/constants/constants";
import { renderSensitivityValue } from "../../common/utilities";
import LocationFilterComponent from "./LocationFilterComponent.js";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "390px",
    overflowY: "scroll",
    borderLeft: "2px solid grey",
    padding: "10px",
  },
  level: {
    borderTop: "solid 2px #979797",
    marginTop: "15px",
    width: "84%",
  },
  resolve: {
    borderBottom: "solid 2px #979797",
    borderTop: "solid 2px #979797",
    marginTop: "15px",
    width: "90%",
  },
  ul: {
    listStyleType: "none",
  },
  li: {
    paddingBottom: "8px",
  },
  label: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.14",
    letterSpacing: "normal",
    color: "black",
  },
  FilterHead: {
    fontSize: "12px",
    lineHeight: 1.22,
    color: "black",
  },
}));
const useStyles_checkbox = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#137cbd",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
});

export default function FilterComponent(props) {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState(false);

  const classes = useStyles();
  const classes_check = useStyles_checkbox();

  const dataSourceName = useSelector(
    (state) => state.databaseLogsReducer.dataSourceName
  );
  // users for access logs
  const users = useSelector(
    (state) => state.databaseLogsReducer.users
  );
  const dataSourceType = useSelector(
    (state) => state.databaseLogsReducer.dataSourceType
  );
  const databaseAccessRoles = useSelector(
    (state) => state.databaseLogsReducer.databaseAccessRoles
  );
  const policyNames = useSelector(
    (state) => state.databaseLogsReducer.policyNames
  );
  const sensitivityInfo = useSelector(
    (state) => state.databaseLogsReducer.sensitivityInfo
  );
  const dataSourceNameFilter = useSelector(
    (state) => state.databaseLogsReducer.apiDataSourceName
  );
  const dataSourceTypeFilter = useSelector(
    (state) => state.databaseLogsReducer.apiDataSourceType
  );
  const databaseAccessRolesFilter = useSelector(
    (state) => state.databaseLogsReducer.apiDatabaseAccessRoles
  );
  const policyNamesFilter = useSelector(
    (state) => state.databaseLogsReducer.apiPolicyNames
  );
  const sensitivityInfoFilter = useSelector(
    (state) => state.databaseLogsReducer.apiSensitivityInfo
  );
  const fromTimeRange = useSelector(
    (state) => state.databaseLogsReducer.fromTimeValue
  );
  const toTimeRange = useSelector(
    (state) => state.databaseLogsReducer.toTimeValue
  );
  const location = useSelector(
    (state) => state.databaseLogsReducer.location
  );
  const locationList = useSelector(
    (state) => state.databaseLogsReducer.locationList
  );
  const alertId = useSelector((state) => state.databaseLogsReducer.alertId);

  const DatabaseLogsClickHandler = (event, name) => {
    switch (name) {
      case "dataSourceName": {
        dispatch({ type: "SET_DATA_SOURCE_NAME", payload: event.target.value });
        dispatch({
          type: "MAINTAIN_SINGLE_DATA",
          payload: {
            name: event.target.name,
            value: event.target.value,
            dispatchName: "SET_DATA_SOURCE_NAME",
          },
        });
        break;
      }
      case "dataSourceType": {
        dispatch({ type: "SET_DATA_SOURCE_TYPE", payload: event.target.value });
        dispatch({
          type: "MAINTAIN_SINGLE_DATA",
          payload: {
            name: event.target.name,
            value: event.target.value,
            dispatchName: "SET_DATA_SOURCE_TYPE",
          },
        });
        break;
      }
      case "databaseAccessRoles": {
        dispatch({
          type: "SET_DATABASE_ACCESS_ROLES",
          payload: event.target.value,
        });
        dispatch({
          type: "MAINTAIN_SINGLE_DATA",
          payload: {
            name: event.target.name,
            value: event.target.value,
            dispatchName: "SET_DATABASE_ACCESS_ROLES",
          },
        });
        break;
      }
      case "policyNames": {
        dispatch({ type: "SET_POLICY_NAMES", payload: event.target.value });
        dispatch({
          type: "MAINTAIN_SINGLE_DATA",
          payload: {
            name: event.target.name,
            value: event.target.value,
            dispatchName: "SET_POLICY_NAMES",
          },
        });
        break;
      }
      case "sensitivityInfo": {
        dispatch({ type: "SET_SENSITIVITY_INFO", payload: event.target.value });
        dispatch({
          type: "MAINTAIN_SINGLE_DATA",
          payload: {
            name: event.target.name,
            value: event.target.value,
            dispatchName: "SET_SENSITIVITY_INFO",
          },
        });
        break;
      }
      case "queryStatement": {
        dispatch({ type: SET_QUERY_STATEMENT, payload: event });
        dispatch({
          type: "MAINTAIN_SINGLE_DATA",
          payload: {
            name: event,
            value: event,
            dispatchName: SET_QUERY_STATEMENT,
          },
        });
        break;
      }
      case "location": {
        const selectedLocation = JSON.parse(event.target.value);
        dispatch({ type: SET_LOCATION, payload: selectedLocation });
        dispatch({
          type: "MAINTAIN_SINGLE_DATA",
          payload: {
            name: `${selectedLocation?.city}, ${selectedLocation?.country}` ,
            value: selectedLocation,
            dispatchName: SET_LOCATION,
          },
        });
      }
      default:
        return "";
    }
  };

  // update store with selected user
  const handleDbSelectUsers = (selectedUsers, user) => {
    dispatch({ type: SET_USERS, payload: user });
    dispatch({
      type: "MAINTAIN_SINGLE_DATA",
      payload: {
        name: user,
        value: user,
        dispatchName: SET_USERS,
      },
    });
  };

  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="filter full-ht">
      <FormLabel style={{ fontSize: "12px", paddingLeft: "10px" }}>
        {"Filter By"}
      </FormLabel>

      <div
        className="full-ht"
        style={{
          width: "390px",
          minheight: "350px",
          overflow: "auto",
          wordBreak: "break-all",
        }}
      >
        <Accordion
          style={{ boxShadow: "none" }}
          expanded={expanded === "dataSource"}
          onChange={handleChange("dataSource")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel1a-header">
            <Typography className={classes.heading}>
              Data Source Names
            </Typography>
            <Typography className={classes.heading}>
              {dataSourceNameFilter.length || ""}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{
                minheight: "350px",
                minWidth: "225px",
                display: "flex",
                flexDirection: "column",
                // marginTop: "2rem",
                paddingLeft: "1rem",
                overflow: "auto",
                height: "240px",
              }}
            >
              <FormGroup aria-label="position" row>
                <>
                  {dataSourceName &&
                    dataSourceName.map((each, index) => {
                      return (
                        <FormControlLabel
                          value={each.id}
                          name={each.aliasName}
                          id={index}
                          control={
                            <Checkbox
                              className={classes_check.root}
                              checked={dataSourceNameFilter.includes(each.id)}
                              onChange={(event) =>
                                DatabaseLogsClickHandler(
                                  event,
                                  "dataSourceName"
                                )
                              }
                              color="default"
                              checkedIcon={
                                <span className="checkbox-icon checked-icon" />
                              }
                              icon={<span className="checkbox-icon" />}
                              inputProps={{
                                "aria-label": "decorative checkbox",
                              }}
                            />
                          }
                          label={
                            <p className={classes.label}>{each.aliasName}</p>
                          }
                          labelPlacement="end"
                        />
                      );
                    })}
                </>
              </FormGroup>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{ boxShadow: "none" }}
          expanded={expanded === "dataSourceType"}
          onChange={handleChange("dataSourceType")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel1a-header">
            <Typography className={classes.heading}>
              Data Source Type
            </Typography>
            <Typography className={classes.heading}>
              {dataSourceTypeFilter.length || ""}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{
                minheight: "350px",
                display: "flex",
                flexDirection: "column",
                // marginTop: "2rem",
                paddingLeft: "1.5rem",
                overflow: "auto",
                height: "100px",
              }}
            >
              <FormGroup aria-label="position" row>
                <>
                  {dataSourceType &&
                    dataSourceType.map((each, index) => {
                      return (
                        <FormControlLabel
                          value={each.code}
                          name={each.code}
                          id={index}
                          control={
                            <Checkbox
                              className={classes_check.root}
                              checked={dataSourceTypeFilter.includes(each.code)}
                              onChange={(event) =>
                                DatabaseLogsClickHandler(
                                  event,
                                  "dataSourceType"
                                )
                              }
                              color="default"
                              checkedIcon={
                                <span className="checkbox-icon checked-icon" />
                              }
                              icon={<span className="checkbox-icon" />}
                              inputProps={{
                                "aria-label": "decorative checkbox",
                              }}
                            />
                          }
                          label={<p className={classes.label}>{each.code}</p>}
                          labelPlacement="end"
                        />
                      );
                    })}
                </>
              </FormGroup>
            </div>
          </AccordionDetails>
        </Accordion>
        {/* <Accordion
          style={{ boxShadow: "none" }}
          expanded={expanded === "databaseAccessRole"}
          onChange={handleChange("databaseAccessRole")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel1a-header">
            <Typography className={classes.heading}>
              Database Access Roles
            </Typography>
            <Typography className={classes.heading}>
              {databaseAccessRolesFilter.length || ""}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{
                minheight: "350px",
                display: "flex",
                flexDirection: "column",
                // marginTop: "2rem",
                paddingLeft: "1.5rem",
                overflow: "auto",
                height: "250px",
              }}
            >
              <FormGroup aria-label="position" row>
                <>
                  {databaseAccessRoles &&
                    databaseAccessRoles.map((each, index) => {
                      return (
                        <FormControlLabel
                          value={each.id}
                          id={index}
                          name={each.name}
                          control={
                            <Checkbox
                              className={classes_check.root}
                              checked={databaseAccessRolesFilter.includes(
                                each.id
                              )}
                              onChange={(event) =>
                                DatabaseLogsClickHandler(
                                  event,
                                  "databaseAccessRoles"
                                )
                              }
                              color="default"
                              checkedIcon={
                                <span className="checkbox-icon checked-icon" />
                              }
                              icon={<span className="checkbox-icon" />}
                              inputProps={{
                                "aria-label": "decorative checkbox",
                              }}
                            />
                          }
                          label={<p className={classes.label}>{each.name}</p>}
                          labelPlacement="end"
                        />
                      );
                    })}
                </>
              </FormGroup>
            </div>
          </AccordionDetails>
        </Accordion> */}
        <Accordion
          style={{ boxShadow: "none" }}
          expanded={expanded === "sensitivityInfo"}
          onChange={handleChange("sensitivityInfo")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel1a-header">
            <Typography className={classes.heading}>
              Sensitivity Level
            </Typography>
            <Typography className={classes.heading}>
              {sensitivityInfoFilter.length || ""}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormGroup aria-label="position" row>
              <>
                {sensitivityInfo &&
                  sensitivityInfo.map((each, index) => {
                    return (
                      <FormControlLabel
                        value={each.code}
                        name={each.code}
                        id={index}
                        control={
                          <Checkbox
                            className={classes_check.root}
                            checked={sensitivityInfoFilter.includes(each.code)}
                            onChange={(event) =>
                              DatabaseLogsClickHandler(event, "sensitivityInfo")
                            }
                            color="default"
                            checkedIcon={
                              <span className="checkbox-icon checked-icon" />
                            }
                            icon={<span className="checkbox-icon" />}
                            inputProps={{ "aria-label": "decorative checkbox" }}
                          />
                        }
                        label = {<LevelComponent  level= {renderSensitivityValue(each.code)}></LevelComponent>}
                        labelPlacement="end"
                      />
                    );
                  })}
              </>
            </FormGroup>
          </AccordionDetails>
        </Accordion>
        {alertId !== "" && (
          <Accordion
            style={{ boxShadow: "none" }}
            expanded={expanded === "alertId"}
            onChange={handleChange("alertId")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Alert ID</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box p={1}>
                <Chip
                  label={alertId}
                  size="medium"
                  style={{ borderRadius: "3px" }}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        )}
        {/* <Accordion
          style={{ boxShadow: "none" }}
          expanded={expanded === "alertPolicyId"}
          onChange={handleChange("alertPolicyId")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel1a-header">
            <Typography className={classes.heading}>Policy Name</Typography>
            <Typography className={classes.heading}>
              {policyNamesFilter.length || ""}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{
                minheight: "350px",
                display: "flex",
                flexDirection: "column",
                // marginTop: "2rem",
                paddingLeft: "1.5rem",
                overflow: "auto",
                height: "240px",
              }}
            >
              <FormGroup aria-label="position" row>
                <>
                  {policyNames &&
                    policyNames.map((each, index) => {
                      return (
                        <FormControlLabel
                          value={each.id}
                          name={each.name}
                          id={index}
                          control={
                            <Checkbox
                              className={classes_check.root}
                              checked={policyNamesFilter.includes(each.id)}
                              onChange={(event) =>
                                DatabaseLogsClickHandler(event, "policyNames")
                              }
                              color="default"
                              checkedIcon={
                                <span className="checkbox-icon checked-icon" />
                              }
                              icon={<span className="checkbox-icon" />}
                              inputProps={{
                                "aria-label": "decorative checkbox",
                              }}
                            />
                          }
                          label={<p className={classes.label}>{each.name}</p>}
                          labelPlacement="end"
                        />
                      );
                    })}
                </>
              </FormGroup>
            </div>
          </AccordionDetails>
        </Accordion> */}
        <Accordion
          style={{ boxShadow: "none" }}
          expanded={expanded === "timeRange"}
          onChange={handleChange("timeRange")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel1a-header">
            <Typography className={classes.heading}>Time Range</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {fromTimeRange && toTimeRange && (
              <DatabaseDateRangePicker
                loc="database-logs"
                timeStamps={props.timeStamps}
              />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{ boxShadow: "none" }}
          expanded={expanded === "Users"}
          onChange={handleChange("Users")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel1a-header">
            <Typography className={classes.heading}>Users</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DbusersComponent
              selectedUsers={users}
              handleSelectUsers={handleDbSelectUsers}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{ boxShadow: "none" }}
          expanded={expanded === "Query"}
          onChange={handleChange("Query")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel1a-header">
            <Typography className={classes.heading}>Query statement</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <QueryStatementComponent
              DatabaseLogsClickHandler={DatabaseLogsClickHandler}
            />
          </AccordionDetails>
        </Accordion>
        <LocationFilterComponent
          locationList={locationList}
          classes={classes}
          classes_check={classes_check}
          expanded={expanded}
          handleChange={handleChange}
          DatabaseLogsClickHandler={DatabaseLogsClickHandler}
        />
      </div>
    </div>
  );
}
