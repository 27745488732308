import Paper from '@material-ui/core/Paper';
import './Badge.scss'

export default function AlertBadge({ title, parentprops, InspectContent, location }) {
  if (!parentprops) {
    return null;
  }
  const {
    alert_count_high = 0,
    alert_count_medium = 0,
    alert_count_low = 0,
  } = parentprops;

  const alertBadgeData = [
    {
      title: 'High',
      color: 'high',
      count: alert_count_high,
    },
    {
      title: 'Medium',
      color: 'medium',
      count: alert_count_medium,
    },
    {
      title: 'Low',
      color: 'low',
      count: alert_count_low,
    },
  ];
  const paperContainerClassName = () => {
    if (InspectContent) {
      return "chip-container alerts inspect-content";
    } else if (location) {
      return "chip-container location";
    } else {
      return "chip-container alerts"
    }
  }
  const badgeDataContainerClassNames = () => {
    if (InspectContent) {
      return "alerts-container inspect-content-header";
    } else if (location) {
      return "alerts-container locationContainer";
    } else {
      return "alerts-container"
    }
  }
  return (
    <Paper component="ul" className={paperContainerClassName()}>
      <div className="dataOwnerTitles">{title}</div>
      <div className={badgeDataContainerClassNames()}>
        {alertBadgeData.map((row) => {
          return (
            <div className={location ? "locationBadges" : ""}>
              <div className={`squareBadge ${row.color}`} title={row.title}>
                <span className="squareBadge-text">{row.count}</span>
              </div>
            </div>
          );
        })}
      </div>
    </Paper>
  );
}