import React, { useState } from "react";
import Toggler from "../../shared/Toggler";
import ShowDataMap from "../showDataMap";

function ManageDataMap({setActiveTab }) {
  const [togglers, setTogglers] = useState([
    { name: "Data Assets", state: "active" },
    { name: "Location", state: "disable" },
  ]);

  const handleTabChange = (newActiveTab) => {
    setActiveTab(newActiveTab);
    setTogglers((prevTogglers) =>
      prevTogglers.map((toggler) =>
        toggler.name === newActiveTab
          ? { ...toggler, state: "active" }
          : { ...toggler, state: "disable" }
      )
    );
  };

  return (
    <div className="togglerandSearch_Conatiner">
      <Toggler
        togglers={togglers}
        setTogglers={setTogglers}
        handleToggle={handleTabChange}
      />
    </div>
  );
}

export default ManageDataMap;
