import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import { Typography } from "material-ui-core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useSelector } from "react-redux";

function LocationFilterComponent({
  locationList,
  classes,
  classes_check,
  expanded,
  handleChange,
  DatabaseLogsClickHandler
}) {
  const location = useSelector((state) => state.databaseLogsReducer.location) || []

  const renderLocationNames = (data) => `${data?.city}, ${data?.country}`;

  return (
    <div className="location-accordion">
      <Accordion
        expanded={expanded === "locations"}
        onChange={handleChange("locations")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel1a-header">
          <Typography className={classes.heading}>Locations</Typography>
          <Typography className={classes.heading}>
            {location.length || ""}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="location-details-container">
            <FormGroup aria-label="position" row>
              {locationList &&
                locationList.map((each, index) => (
                  <FormControlLabel
                    key={index}
                    value={JSON.stringify(each)} // Stringify the object
                    name={renderLocationNames(each)}
                    id={index}
                    control={
                      <Checkbox
                        className={classes_check.root}
                        checked={location?.some(
                          (loc) =>
                            loc.city === each.city &&
                            loc.country === each.country
                        )} // Check match based on city and country
                        onChange={(event) =>
                          DatabaseLogsClickHandler(event, "location")
                        }
                        color="default"
                        checkedIcon={
                          <span className="checkbox-icon checked-icon" />
                        }
                        icon={<span className="checkbox-icon" />}
                        inputProps={{
                          "aria-label": "decorative checkbox",
                        }}
                      />
                    }
                    label={
                      <p className={classes.label}>
                        {renderLocationNames(each)}
                      </p>
                    }
                    labelPlacement="end"
                  />
                ))}
            </FormGroup>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default LocationFilterComponent;
