import React, { useEffect } from "react";
import DataMapInspect from "../shared/DataMapInspect";
import LocationInspect from "./manageDataMap/locationInspectContent/LocationInspect";

export default function InspectManager({
  nodeName,
  creationTime,
  dataSource,
  selectedAppByType,
  selectedApp,
  selectedAlertIds,
  bottomTrayEnable,
  bottomTrayEnableEve,
  tooltipData,
  activeTab,
  locationData
}) {
  const renderInspect = () => {
    if (nodeName && activeTab =="Data Assets") {
      return (
        <DataMapInspect
          nodeName={nodeName}
          creationTime={creationTime}
          dataSource={dataSource}
          selectedAppByType={selectedAppByType}
          selectedApp={selectedApp}
          selectedAlertIds={selectedAlertIds}
          bottomTrayEnable={bottomTrayEnable}
          bottomTrayEnableEve={bottomTrayEnableEve}
          tooltipData={tooltipData}
        />
      );
    } else if (activeTab === "Location" && bottomTrayEnable) {
      return (
        <LocationInspect activeTab={activeTab} bottomTrayEnableEve={bottomTrayEnableEve} locationData={locationData}/>
      )
    }
  }
  return <div>{renderInspect()}</div>;
}
