import { getAxiosHeaders, getService } from "../../../library/RestAPI";
import { ApiCall_DatabaseRoles, ApiCall_DataSourceNames, ApiCall_DataSourceTypes, ApiCall_Locations, APPLICATIONS_URL, database_Sensitivity_Fields, GET_ALERT_POLICY_NAMES_URL, SET_LOCATIONLIST } from "../../../redux/constants/constants";

export const fetchAndDispatchAllData = async (dispatch) => {
    const getDataService = (url) => {
      return getService({
        method: "GET",
        url: `${url}`,
        headers: getAxiosHeaders(true),
      });
    };
  
    try {
      const urls = [
        ApiCall_DataSourceNames,
        ApiCall_DatabaseRoles,
        ApiCall_DataSourceTypes,
        database_Sensitivity_Fields,
        GET_ALERT_POLICY_NAMES_URL,
        APPLICATIONS_URL,
        ApiCall_Locations
      ];
  
      const responses = await Promise.all(urls.map((url) => getDataService(url)));
  
      responses.forEach((apiData, index) => {
        switch (index) {
          case 0:
            dispatch({ type: "DATA_SOURCE_NAME", payload: apiData.data || "" });
            break;
          case 1:
            dispatch({ type: "DATABASE_ACCESS_ROLES", payload: apiData.data.content });
            break;
          case 2:
            dispatch({ type: "DATA_SOURCE_TYPE", payload: apiData.data.content });
            break;
          case 3:
            dispatch({ type: "SENSITIVITY_INFO", payload: apiData.data.content });
            break;
          case 4:
            dispatch({ type: "POLICY_NAMES", payload: apiData.data.content });
            break;
          case 5:
            dispatch({ type: "APPLICATIONS", payload: apiData.data.content });
            break;
          case 6:
            dispatch({ type: SET_LOCATIONLIST, payload: apiData.data });
            break;
          default:
            break;
        }
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  