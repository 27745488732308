import {
  CLEAR_LOCATION_DATA,
  SET_LOCATION_DATA,
  SET_LOCATION_LOADER,
  SET_LOCATION_SNACKBAR_MESSAGE,
  SET_LOCATION_TABLE_DATA,
} from "../locationConstants";

const initialState = {
  locationData: null,
  loader: false,
  locationTableData: null,
  snackBarMessage: null,
  isLocation: false,
};

export const locationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOCATION_DATA: {
      return {
        ...state,
        locationData: action.payload,
      };
    }
    case SET_LOCATION_LOADER: {
      return {
        ...state,
        loader: action.payload,
      };
    }
    case SET_LOCATION_SNACKBAR_MESSAGE: {
      return {
        ...state,
        snackBarMessage: action.payload,
      };
    }
    case SET_LOCATION_TABLE_DATA: {
      return {
        ...state,
        locationTableData: action.payload,
      };
    }
    case SET_LOCATION_SNACKBAR_MESSAGE: {
      return {
        ...state,
        snackBarMessage: action.payload
      }
    }
    case CLEAR_LOCATION_DATA: {
      return {
        ...state,
        locationData: null,
        loader: false,
        snackBarMessage: null,
      };
    }
    default:
      return state;
  }
};
