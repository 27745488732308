import React from "react";
import AppBar from "@material-ui/core/AppBar";
import {
  ContentRow,
  LocationTitle,
  renderUsersWithAlerts,
  shortenedNumber,
} from "../locationCommonComponents";
import { isNull } from "lodash";
import { icons } from "../../../common/utilities";
import AlertBadge from "../../../shared/Badge/AlertBadge";
import LocationContent from "./LocationContent";

export default function LocationInspect({
  activeTab,
  bottomTrayEnableEve,
  locationData,
}) {
  const headerContent = () => {
    if (!isNull(locationData)) {
      return (
        <div className="location-inspect-title-header">
          <div className="inspect-title location">
            <LocationTitle
              title={locationData?.title}
              count={locationData?.totalActivity}
            />
          </div>
          <div className="inspect-title-right location">
            <div className="inspect-dataSource">
              <ContentRow
                title="Data Sources"
                icon={locationData?.dataSources}
                location="inspect"
              />
            </div>
            <div className="inspect-creationTime location">
              <ContentRow
                title="Most Activity"
                icon={icons[locationData.mostAccessed.type]}
                sourceName={locationData.mostAccessed.name}
                location="inspect"
                count={locationData?.mostAccessed?.accessCount}
              />
              <ContentRow
                title="Least Activity"
                icon={icons[locationData.leastAccessed.type]}
                sourceName={locationData.leastAccessed.name}
                location="inspect"
                count={locationData?.leastAccessed?.accessCount}
              />
            </div>
            <div className="inspect-alerts">
              <AlertBadge
                title={"Alerts:"}
                parentprops={locationData.alerts}
                InspectContent={true}
              />
            </div>
            <div className="inspect-alerts">
              <div className="contentHeaderContainer inspect">
                <div className="contentHeaders">Users With Alerts:</div>
                <div className="locationMapIconData">
                  {renderUsersWithAlerts(locationData.usersWithAlerts)}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
  return (
    <div className="dataMap-bottom-view">
      <div className="inspect-contents">
        <AppBar
          position="static"
          className="inspect-contents-header"
          color="Default"
        >
          <div
            className="header-bar"
            onClick={() => {
              bottomTrayEnableEve(false);
            }}
          ></div>
          {headerContent()}
        </AppBar>
        <div>
          <LocationContent location={!isNull(locationData) ? locationData : null}/>
        </div>
      </div>
    </div>
  );
}