import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_LOCATION_TABLE_DATA } from "../locationConstants";
import { isEmpty, isNull } from "lodash";
import AlertBadge from "../../../shared/Badge/AlertBadge";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { icons } from "../../../common/utilities";
import FlowGroupIcon from "../../../../assets/vizr_images/view_logs_black.svg";
import { DATABASE_FROM_TIME_VALUE, DATABASE_TO_TIME_VALUE, SET_LOCATION, SET_USERS } from "../../../../redux/constants/constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { fetchAndDispatchAllData } from "../mapUtilities";


export default function LocationContent({ location }) {
  const history = useHistory();
  const locationTableData = useSelector(
    (state) => state.locationReducer.locationTableData
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: GET_LOCATION_TABLE_DATA, payload: location.position });
  }, []);

  console.log(location?.title.split(','),"sdfghj")

  const renderNull = () => {
    return <>-</>;
  };
  const renderDataSourceWithIcon = (params) => {
    if (!isEmpty(params)) {
      const icon = icons[params?.value.type];
      return (
        <div className="dataSource-name-icon location">
          {params.value.name && <div>{params.value.name}</div>}
          {icon && (
            <div>
              <img
                src={icon}
                alt="Data Source Icon"
                title={params.value.type}
                className="dataSource-icon-img"
              />
            </div>
          )}
        </div>
      );
    } else {
      return renderNull();
    }
  };
  const renderUser = (params) => {
    if (isNull(params.value)) {
      return renderNull();
    } else {
      return <div>{params.value}</div>;
    }
  };
  const renderNumberOfAccess = (params) => {
    if (isNull(params.value)) {
      return renderNull();
    } else {
      return <div>{params.value}</div>;
    }
  };
  const renderAlerts = (params) => {
    if (isNull(params.value)) {
      return renderNull();
    } else {
      const alerts = params.value
      const alertsObj = {
        alert_count_high: alerts.HIGH || 0,
          alert_count_medium: alerts.MEDIUM || 0,
          alert_count_low: alerts.LOW || 0,
      }
      return (
        <AlertBadge
          parentprops={alertsObj}
          InspectContent={true}
        />
      );
    }
  };

  const applyAccessLogsFilter = (data) => {
    try {
      // Extract city and country from location
      const [city = "", country = ""] = location?.title?.split(",") || [];
      const filterData = {city, country, data}
      const serializedFilterData = JSON.stringify(filterData);
      // Dispatch actions
      history.push({
        pathname: "/tenant/databaseLogs",
        state: {
          configuration: serializedFilterData,
          tag:"directDbLogsLocation",
        }
      });
    } catch (error) {
      console.error("Error applying access logs filter:", error);
    }
  };

  const renderLastAccess = (params) => {
    if (isNull(params.value)) {
      return renderNull();
    }

    const date = new Date(params.value);
    const renderDateTime = () => {
      return `${String(date.getMonth() + 1).padStart(2, "0")}-${String(
      date.getDate()
    ).padStart(2, "0")}-${date.getFullYear()} ${date.getHours()}:${String(
      date.getMinutes()
    ).padStart(2, "0")}`;
    }

    // Use template literals and padStart for formatting
    return (
      <div className="dateTimeLocationContainer">
        <div>{renderDateTime()}</div>
        <div>
          <img className="DAccessLogsImg" src={FlowGroupIcon} alt="DA Icon" title="View 3-Day Data Access Logs" onClick={() => applyAccessLogsFilter(params.row)}/>
        </div>
      </div>
    )
  };
  
  
  const columns = [
    {
      field: "dataSource",
      headerName: "Data Source",
      flex: 1,
      sortable: false,
      align: "left",
      headerAlign: "left",
      renderCell: renderDataSourceWithIcon,
    },
    {
      field: "username",
      headerName: "Username",
      flex: 0.9,
      sortable: false,
      align: "left",
      headerAlign: "left",
      renderCell: renderUser,
    },
    {
      field: "numberOfAccess",
      headerName: "Number of Activities",
      flex: 0.5,
      sortable: false,
      align: "left",
      headerAlign: "left",
      renderCell: renderNumberOfAccess,
    },
    {
      field: "alertsBySeverity",
      headerName: "Alerts",
      flex: 0.8,
      sortable: false,
      align: "left",
      headerAlign: "left",
      renderCell: renderAlerts,
    },
    {
      field: "lastAccessed",
      headerName: "Last Accessed",
      flex: 0.5,
      sortable: false,
      align: "left",
      headerAlign: "left",
      renderCell: renderLastAccess,
    },
  ];

  const renderTable = () => {
    if (!isEmpty(locationTableData)) {
      // Add an id based on the index to each row
      const rowsWithId = locationTableData.map((row, index) => ({
        ...row,
        id: index,  // Add index as the unique id
      }));
  
      return (
        <div className="custom-data-grid-main location">
          <DataGrid
            className="custom-data-grid"
            rows={rowsWithId}
            getRowId={(row) => row.id}
            columns={columns}
            rowHeight={90}
            onRowClick={null}
            showCellRightBorder={false}
            showColumnRightBorder={false}
            showCellVerticalBorder={true}
            disableColumnFilter={true}
            disableColumnSelector
            disableColumnMenu
            pagination={false}
            hideFooterPagination={true}
            hideFooter={true}
            getRowSpacing={(params) => ({
              top: 10,
              bottom: params.isLastVisible ? 0 : 5,
            })}
          />
        </div>
      );
    }
  };
  
  
  return <div className="ContentGrid">{renderTable()}</div>;
}