import React, { useState, useEffect } from "react";
import ReactSlider from "react-slider";
import { Tooltip } from "@material-ui/core";
import PropTypes from "prop-types";
import { LOCATION_LEVEL_INFO } from "../../redux/constants/constants";
import "../views/manageDataMap/locationInspectContent/locationMap.scss";

/**
 * Component for rendering the value label tooltip.
 * @param {Object} props - Component props.
 * @param {React.Element} props.children - The slider thumb element.
 * @param {boolean} props.open - Whether the tooltip is open.
 * @param {number} props.value - The value to display in the tooltip.
 */
function ValueLabelComponent({ children, open, value }) {
  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};

/**
 * Renders location tolerance level information with an icon and text.
 */
const renderToleranceLevelInformation = () => (
  <div className="locationLevelInfoContainer">
    <img src="/infoIconBlue.svg" alt="Location level Information" />
    <p className="toleranceLevelInformationText">{LOCATION_LEVEL_INFO}</p>
  </div>
);

/**
 * LocationTriggerComponent handles a slider for setting risk levels (LOW, MEDIUM, HIGH).
 * @param {Object} props - Component props.
 * @param {boolean} props.editStatus - Whether the slider is editable.
 * @param {string} props.riskLevelValue - Initial risk level string (e.g., "LOW_90_MEDIUM_90_HIGH_9").
 * @param {function} props.handleRiskLevel - Callback function to handle risk level changes.
 */
function LocationTriggerComponent({ editStatus, riskLevelValue, handleRiskLevel }) {
  // Initial state with default values for LOW, MEDIUM, and HIGH
  const [values, setValues] = useState([
    { name: "LOW", label: "L", value: 20 },
    { name: "MEDIUM", label: "M", value: 50 },
    { name: "HIGH", label: "H", value: 80 },
  ]);

  const [activeIndex, setActiveIndex] = useState(null);

  // Effect to parse riskLevelValue and update state on initial render
  useEffect(() => {
    if (riskLevelValue) {
      // Example input: "LOW_90_MEDIUM_90_HIGH_9"
      const parts = riskLevelValue.split("_");

      const parsedValues = {
        LOW: parseInt(parts[1], 10),
        MEDIUM: parseInt(parts[3], 10),
        HIGH: parseInt(parts[5], 10),
      };

      setValues([
        { name: "LOW", label: "L", value: parsedValues.LOW },
        { name: "MEDIUM", label: "M", value: parsedValues.MEDIUM },
        { name: "HIGH", label: "H", value: parsedValues.HIGH },
      ]);
    }
  }, [riskLevelValue]);

  /**
   * Handles slider value changes, applying constraints and formatting the output string.
   * @param {number[]} newValues - Array of new values for LOW, MEDIUM, and HIGH.
   * @param {number} index - The index of the active thumb.
   */
  const handleChange = (newValues, index) => {
    let [low, medium, high] = newValues;

    // Apply constraints for LOW (0 to 50)
    low = Math.min(low, 50);
    if (low >= medium) low = medium - 1;

    // Apply constraints for MEDIUM (50 to 75)
    medium = Math.max(medium, 50, low + 1);
    medium = Math.min(medium, 75);
    if (medium >= high) medium = high - 1;

    // Apply constraints for HIGH (75 to 100)
    high = Math.max(high, 75, medium + 1);
    high = Math.min(high, 100);

    // Update state with constrained values
    setValues([
      { ...values[0], value: low },
      { ...values[1], value: medium },
      { ...values[2], value: high },
    ]);

    // Create the formatted string and call the callback function
    const valueString = `LOW_${low}_MEDIUM_${medium}_HIGH_${high}`;
    handleRiskLevel(valueString);

    setActiveIndex(index);
  };

  return (
    <>
      <div className="locationSliderContainer">
        <ReactSlider
          className="horizontal-slider"
          value={values.map((item) => item.value)}
          onChange={handleChange}
          min={0}
          max={100}
          step={1}
          pearling
          minDistance={1}
          disabled={!editStatus}
          renderThumb={(props, state) => {
            const item = values[state.index];
            return (
              <div title={`${item.name}: ${item.value}`}>
                <ValueLabelComponent open={state.index === activeIndex} value={item.value}>
                  <div {...props} className={`slider-thumb ${item.label}`}>
                    <span className={`slider-label ${item.label}`}>{item.label}</span>
                  </div>
                </ValueLabelComponent>
              </div>
            );
          }}
          onAfterChange={() => setActiveIndex(null)} // Hide tooltip when the thumb is released
        />
      </div>
      {renderToleranceLevelInformation()}
    </>
  );
}

export default LocationTriggerComponent;