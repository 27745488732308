import { NTFS } from "../manageDataSource/dataSourceConstants";
import "./manageDataMap.scss";
import {
  icons,
  renderDataMapIcons,
  renderSmallDonut,
} from "../../common/utilities";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

export const shortenedNumber = (number) => {
  if (number >= 1_000_000_000_000) {
    return (number / 1_000_000_000_000).toFixed(1).replace(/\.0$/, "") + "T"; // Trillions
  } else if (number >= 1_000_000_000) {
    return (number / 1_000_000_000).toFixed(1).replace(/\.0$/, "") + "B"; // Billions
  } else if (number >= 1_000_000) {
    return (number / 1_000_000).toFixed(1).replace(/\.0$/, "") + "M"; // Millions
  } else if (number >= 1_000) {
    return (number / 1_000).toFixed(1).replace(/\.0$/, "") + "k"; // Thousands
  } else {
    return number.toString(); // Less than 1000
  }
};

export const LocationTitle = ({ title, count }) => {
  const formattedCount = shortenedNumber(count); // Apply the updated function

  return (
    <div className="locationMapIconDataBadge">
      <div className="locationHeaderWithBadge">
        <div
          className="locationHeaderContainer"
          title={`Total Activities: ${count}`}
        >
          {count > 0 ? `${title} (${formattedCount})` : title}
        </div>
      </div>
    </div>
  );
};

const renderDataSourceIconData = (dataSources, location) => {
  return Object.entries(dataSources).map(([key, value], index) => {
    const displayValue = value > 999 ? "..." : value; // Show ellipsis if value exceeds 3 digits
    const fullValue = value > 999 ? value : null; // Full count to be shown on hover if exceeds 3 digits

    return (
      <div className="locationMapIconData" key={index}>
        <div
          className="icon-container"
          title={`${key}: ${fullValue || value} `} // Show full count on hover if applicable
        >
          <img
            className={key === NTFS ? "ImageScale NTFS" : "ImageScale"}
            src={icons[key]}
            alt={`${key} icon`}
          />
          <div className={location === "inspect" ? "locationBadge" : "badge"}>
            {displayValue}
          </div>
        </div>
      </div>
    );
  });
};

const renderTitleClasses = (location, icon) => {
  if (location === "inspect") {
    if (typeof icon !== "string") {
      return "contentHeaders dataSource";
    } else {
      return "contentHeaders inspect";
    }
  } else {
    return "contentHeaders";
  }
};

const renderContentRowContainerTitle = (title,count) => {
  if (title !== "Data Sources" && parseFloat(count) > 0) {
    return `Activities: ${parseFloat(count)}`;
  }
};

const renderContentRowTitle = (title, count) => {
  if (title === "Data Sources") {
    return title;
  } else {
    return `${title}(${shortenedNumber(parseFloat(count))})`;
  }
}

export const ContentRow = ({ title, icon, sourceName, location, count }) => (
  <div
    className={
      location === "inspect"
        ? "contentHeaderContainer inspect"
        : "contentHeaderContainer"
    }
  >
    <div
      className={renderTitleClasses(location, icon)}
      title={renderContentRowContainerTitle(title,count)}
    >
      {renderContentRowTitle(title, count)}:
    </div>
    <div
      className={
        location === "inspect"
          ? "locationMapIconData inspect"
          : "locationMapIconData"
      }
    >
      {typeof icon === "string" ? (
        <div className="iconNameContainer">
          <img
            className={
              icon === NTFS ? "ImageScalePixels NTFS" : "ImageScalePixels"
            }
            src={icon}
            alt="icon"
          />
          <div
            className="sourceNameContainer"
            title={`Data Source Name: ${sourceName}`}
          >
            {sourceName}
          </div>
        </div>
      ) : (
        renderDataSourceIconData(icon, location)
      )}
    </div>
  </div>
);

const userIcon = (noUsersWithAlerts) => {
  if (noUsersWithAlerts) {
    return (
      <img
        src="/dataOwnerViewIcons/userIcons/noUsersWithAlerts.svg"
        alt="noUserIcon"
        title="No Users with Alerts"
      />
    );
  } else {
    return (
      <img
        src="/dataOwnerViewIcons/userIcons/noUser.svg"
        alt="userIcon"
        title="Unique Users"
      />
    );
  }
};

const renderDonut = (usersData) => {
  const pieData = [
    {
      color: "#FF2251",
      text: "High",
      count: usersData.HIGH,
      icon: "/dataOwnerViewIcons/userIcons/highUser.svg",
    },
    {
      color: "#FF8421",
      text: "Medium",
      count: usersData.MEDIUM,
      icon: "/dataOwnerViewIcons/userIcons/mediumUser.svg",
    },
    {
      color: "#081981",
      text: "Low",
      count: usersData.LOW,
      icon: "/dataOwnerViewIcons/userIcons/lowUser.svg",
    },
  ];
  return (
    <div className="dataOwnerPieLegend">
      {renderSmallDonut(pieData)}
      <div className="pieDataOwner">
        {pieData.map((e) => {
          return (
            <div className="dataOwnerCount">
              <div title={e.text}>
                <img src={e.icon} />
              </div>
              <div
                className="dataOwnerPieCount"
                style={{ color: e.color }}
                title={e.count}
              >
                {e.count}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const renderUsersWithAlerts = (usersData, location) => {
  const noUsersWithAlerts = usersData.total === 0;
  return (
    <div className="dataOwner-table-totalAlerts">
      <div className="dataOwnerTotalIcon">
        <div className="noUsersWithAlerts">{userIcon(noUsersWithAlerts)}</div>
        <div className="dataOwner-table-count" title={usersData.total}>
          {usersData.total}
        </div>
      </div>
      <div className="inspect-alerts dataOwner">
        {/* Render donut chart based on whether there are users with alerts */}
        {noUsersWithAlerts ? renderSmallDonut([]) : renderDonut(usersData)}
      </div>
    </div>
  );
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const renderLocationSnackBar = (
  openError,
  handleSnackbarClose,
  errorMessage
) => {
  return (
    <Snackbar
      open={openError}
      autoHideDuration={3000}
      onClose={handleSnackbarClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      className="error-snackbar"
    >
      <Alert onClose={handleSnackbarClose} severity="error">
        {errorMessage}
      </Alert>
    </Snackbar>
  );
};
